import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>rico4live@gmail.com</h5>
            <a href="mailto:rico4live@gmail.com" target="_blank" rel="noreferrer">Send a message</a>
          </article>
        </div>

        <div>
        <article className="contact__option">
            <BsLinkedin className="contact__option-icon" />
            <h4>LinkedIn</h4>
            <h5>Richard</h5>
            <a href="https://linkedin.com/in/richard-akinyemi-06b8b5109" target="_blank" rel="noreferrer">Send a message</a>
          </article>
        </div>

      </div>
    </section>
  )
}

export default Contact