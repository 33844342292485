import './footer.css';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>RichieSkript</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#contact">Contact</a></li>
        <li><a href='https://github.com/richierich64?tab=repositories' target='_blank' rel="noreferrer">Projects</a></li>
      </ul>

      <div className='footer__socials'>
        <a href='https://linkedin.com/in/richard-akinyemi-06b8b5109' target='_blank' rel="noreferrer"><BsLinkedin /></a>
        <a href='https://github.com/richierich64?tab=repositories' target='_blank' rel="noreferrer"><FaGithub /></a>    
      </div>

      <div className="footer__copyright">
        <small>&copy; RichieSkript. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer