import React from 'react';
import './header.css';
import ME2 from '../../assets/img2.jpg'
import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header>
      <div className='container header__container'>
        <h5>Hello I'm</h5>
        <h1>Richard Akinyemi</h1>
        <h4 className='text-light'>Full-Stack Javascript Developer</h4>
        
        <HeaderSocials />

        <div className='me'>
          <img src={ME2} alt='' style={{ height: '28rem'}} />
        </div>

        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header