import './about.css';
import ME1 from '../../assets/img1.jpg';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME1} alt='' style={{ height: '35rem'}} />
          </div>
        </div>

        <div className='about__content'>
          <div>
            <p>
              A highly motivated and detail-oriented full stack developer with experience in
              building web applications using React and Node. Proficient in developing responsive
              and intuitive user interfaces using modern front-end technologies, and experienced in
              designing and implementing scalable and secure back-end services. <br />
              Also skilled in developing mobile applications using React Native.
            </p>
            <p>
              An experienced engineer involved in the entire product development lifecycle 
              including the design, development, deployment and maintenance of new and existing features.
            </p>
            <p>
              A big fan of the MERN stack. 
            </p>
          </div>
          
          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About